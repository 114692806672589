import { put, takeLatest, call } from 'redux-saga/effects';
import {
  loadJobApplicationSubmitted,
  errorJobApplicationSubmitted,
  loadedJobApplicationSubmitted,
} from '../actions/jobs';
import GetroClient from '../../api/GetroClient';

export function* loadJobApplicationSubmittedEvent({ payload }) {
  const {
    network,
    jobId,
    resumeUrl,
    firstName,
    lastName,
    usVisaSponsorship,
    usWorkAuthorization,
    linkedinUrl,
    profileUrl,
    email,
    organizationId,
    additionalInfo,
  } = payload;
  try {
    const formattedLinkedinUrl = linkedinUrl.replace(/www./, '').replace(/^(?:(?:http(?:s)?|):\/\/)/, '');
    const applicationPayload = {
      jobId,
      networkId: network.id,
      firstName,
      lastName,
      email,
      linkedinUrl: `https://www.${formattedLinkedinUrl}`,
      usVisaSponsorship,
      usWorkAuthorization,
      additionalInfo,
    };

    if (resumeUrl) {
      applicationPayload.cvUrl = resumeUrl;
    }

    if (profileUrl) {
      applicationPayload.profileUrl = `https://www.${profileUrl
        .replace(/www./, '')
        .replace(/^(?:(?:http(?:s)?|):\/\/)/, '')}`;
    }

    yield call(GetroClient.sendJobApplication, applicationPayload);
    yield put(loadedJobApplicationSubmitted({ network, jobId, organizationId }));
  } catch (e) {
    yield put(errorJobApplicationSubmitted({ jobId }));
  }
}

export default function* jobApplicationWatcher() {
  yield takeLatest(loadJobApplicationSubmitted().type, loadJobApplicationSubmittedEvent);
}

var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"JyNseJY3oIj2G4W0ZNUVY"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/discover";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import { inProduction } from './helpers/environmentHelpers';

const { publicRuntimeConfig } = getConfig();
const { sentryDsn, sentryEnvironment, localSentryErrors } = publicRuntimeConfig;

Sentry.init({
  enabled: inProduction() || localSentryErrors,
  dsn: sentryDsn,
  environment: sentryEnvironment,
  tracesSampleRate: 0.033,
  ignoreErrors: ['ResizeObserver loop', 'window.matchMedia', 'Y is not defined', "Can't find variable: scrollTop"],
});
